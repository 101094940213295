<template>
  <div
    class="custom-dashboard-gauge-chart"
    v-loading="loading"
    element-loading-text="Calculating..."
  >
    <div class="mr-2 mt-1">
      <b class="ml-1">{{ component.component_name }}</b>
      <el-dropdown trigger="click" v-if="!hide_options" style="float: right">
        <el-link
          icon="el-icon-more"
          :underline="false"
          style="transform: rotate(90deg); height: 40px"
        ></el-link>

        <el-dropdown-menu slot="dropdown">
          <a @click="editComponent">
            <el-dropdown-item>Edit</el-dropdown-item>
          </a>
          <a @click="deleteComponent">
            <el-dropdown-item>Delete</el-dropdown-item>
          </a>
        </el-dropdown-menu>
      </el-dropdown>
      <el-button
        class="fullscreen-button"
        icon="el-icon-full-screen"
        type="text"
        @click="expand"
        style="float: right"
      >
      </el-button>
    </div>
    <div
      v-if="component && component.filters && component.filters.length"
      class="ml-1 mt-1 d-flex"
    >
      <div v-for="(filter, index) in component.filters" :key="index">
        <div></div>
        <div>{{ getEntityLabel(filter.entity_id) }}:</div>
        <el-select
          :key="index + '_' + filter.entity_id"
          size="mini"
          class="mr-1"
          style="width: 150px"
          v-model="filter.value"
          filterable
          clearable
          multiple
          collapse-tags
          @change="getNecessaryInfo"
        >
          <el-option
            v-for="(data, index) in filtersData[filter.entity_id]"
            :key="index"
            :value="data._id"
            :label="getLabel(data, index, filter.entity_id)"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="gauge-container">
      <vue-gauge
        v-if="!refresh"
        :refid="component.keyIndex"
        class="mt-3"
        :options="getChartOptions"
        :style="gaugeContainerStyle"
      >
      </vue-gauge>
      <div class="legend">
        <div
          class="legend-item"
          v-for="(color, index) in getChartOptions.arcColors"
          :key="index"
        >
          <span class="legend-color" :style="{ backgroundColor: color }"></span>
          <span class="legend-label" style="font-size: 12px">
            {{ getDelemeterRangeLabel(index) }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueGauge from "vue-gauge";
import { postAPICall } from "../../helpers/httpHelper";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  components: { VueGauge },
  data() {
    return {
      options: {
        needleValue: 0,
        centralLabel: "-",
        needleColor: "#bebebe",
        arcDelimiters: [33, 66],
        arcColors: ["#f56c6c", "#e6a23c", "#67c23a"],
        arcOverEffect: true,
        chartWidth: 400,
        rangeLabelFontSize: 14,
      },
      refresh: true,
      filtersData: {},
      filtersEntities: {},
      loading: false,
      isFullScreen: false,
    };
  },
  props: ["component", "index", "hide_options"],
  async mounted() {
    this.loading = true;
    await this.getFilters();
    await this.getNecessaryInfo();
    this.loading = false;
  },
  computed: {
    ...mapGetters("auth", ["getDefaultDateFormat", "getActiveContactType"]),
    getChartOptions() {
      return this.options;
    },
    gaugeContainerStyle() {
    return {
      // marginLeft: this.isFullScreen ? '360px' : '70px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
  }
  },
  methods: {
    expand() {
      this.isFullScreen = !this.isFullScreen;
      this.component.isExpandTable = !this.component.isExpandTable;
      this.$emit("expendTable", this.index);
    },
    async getFilters() {
      if (this.component?.filters?.length) {
        await Promise.all(
          this.component.filters.map(async (filter) => {
            let params = {
              entity_id: filter.entity_id,
              limit: 500,
              page: 1,
              filters: [],
            };
            let filtersResponse = await postAPICall(
              "POST",
              "/entities-data/entity/data",
              params
            );
            this.filtersData[filter.entity_id] = filtersResponse.data;
            this.filtersEntities[filter.entity_id] =
              filtersResponse.selectedEntity;
          })
        );
      }
    },
    async getNecessaryInfo() {
      this.refresh = true;
      if (this.$route.name != "entity-custom-dashboard-config") {
        this.setApplicationUserFilters();
      }
      let response = await postAPICall(
        "POST",
        "/entities/gauge-chart-count",
        this.component
      );
      this.resetCustomFilters();
      let value =
        (Number(
          response.data.numerator.count / response.data.denominator.count
        ) || 0) * 100;
      this.options = {
        ...this.options,
        ...{
          needleValue: value,
          centralLabel: `${parseFloat(value).toFixed(2)}%`,
          arcDelimiters: this.component?.customization?.delemeters
            ?.split(",")
            ?.map((n) => Number(n)) || [33, 66],
          arcColors: this.component?.customization?.delemeterColors || [
            "#f56c6c",
            "#e6a23c",
            "#67c23a",
          ],
        },
      };
      this.refresh = false;
    },
    getDelemeterRangeLabel(index) {
      let delemeters = this.component.customization.delemeters.split(",");
      switch (index) {
        case 0:
          return `0 - ${delemeters[index]}`;
        case delemeters.length:
          return `${delemeters[index - 1]} - 100`;
        default:
          return `${delemeters[index - 1]} - ${delemeters[index]}`;
      }
    },
    getLabel(item, index, entity) {
      let primaryField = this.filtersEntities[entity]?.primaryFields[0];
      if (primaryField) {
        return primaryField.inputType == "ENTITY"
          ? item.entityData[primaryField.template_id][
              primaryField.key + "/name"
            ]
          : primaryField.inputType == "DATE"
          ? moment(
              item.entityData[primaryField.template_id][primaryField.key]
            ).format(this.getDefaultDateFormat)
          : primaryField.inputType == "DATE_TIME"
          ? moment(
              item.entityData[primaryField.template_id][primaryField.key]
            ).format(this.getDefaultDateFormat + " HH:mm:ss")
          : item.entityData[primaryField.template_id][primaryField.key];
      }
      return "data " + index;
    },
    getEntityLabel(entityId) {
      return this.filtersEntities?.[entityId]?.name;
    },
    deleteComponent() {
      this.$confirm("Are you sure to delete the Gauge Chart?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$emit("deleteComponent", this.index);
      });
    },
    editComponent() {
      this.$emit("editComponent", this.index);
    },
    setApplicationUserFilters() {
      if (this.component.numerator?.relationship_id) {
        this.component.numerator["customFilters"] = [
          {
            data_type: "RELATION",
            field: this.component.numerator.relationship_id.includes("#")
              ? "nested#related_to/"
              : "self#related_to",
            operator: "in",
            query_type: "AND",
            nested_related_id:
              this.component.numerator.relationship_id.includes("#")
                ? this.component.numerator.relationship_id.split("#")[0]
                : "",
            relation_entity_id: this.getActiveContactType?.contact_type?._id,
            value: [this.getActiveContactType?.account_data_id],
          },
        ];
      }
      if (this.component.denominator?.relationship_id) {
        this.component.denominator["customFilters"] = [
          {
            data_type: "RELATION",
            field: this.component.denominator.relationship_id.includes("#")
              ? "nested#related_to/"
              : "self#related_to",
            operator: "in",
            query_type: "AND",
            nested_related_id:
              this.component.denominator.relationship_id.includes("#")
                ? this.component.denominator.relationship_id.split("#")[0]
                : "",
            relation_entity_id: this.getActiveContactType?.contact_type?._id,
            value: [this.getActiveContactType?.account_data_id],
          },
        ];
      }
    },
    resetCustomFilters() {
      delete this.component.numerator["customFilters"];
      delete this.component.denominator["customFilters"];
    },
  },
};
</script>

<style>
.custom-dashboard-gauge-chart {
  border: 1px solid #eaeaea;
  min-height: 370px !important;
  margin-bottom: 30px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
  background-color: #ffffff;
}
.gauge-container {
  width: 100%;
  height: 100%;
}
.legend {
  display: flex;
  flex-wrap: wrap; 
  justify-content: center; 
  margin-left: 0; 
  overflow: hidden;
  gap: 10px; 
}
.legend-item {
  display: flex;
  align-items: center;
  margin: 0 10px;
}
.legend-color {
  width: 40px;
  height: 10px;
  display: inline-block;
  margin-right: 7px;
}
.legend-label {
  font-size: 14px;
  vertical-align: middle;
}
</style>
